export const initialState = {
  // HOME PAGE
  CATALOG_CONTENTS: [],
  ARRIVAL_CONTENT: [],
  PROMOTION_CONTENT: [],
  TRENDING_CONTENT: [],
  MAINBANNER_CONTENT: [],
  PROMOTION_POPUP_CONTENT: [],
  PROMOTION_POPUP_CLOSE_CONTENT: false,
  BRAND__DATA__CONTENT: [],
  //CATEGORY
  promotionBannerData: [],
  //maintnacepage
  downtimeInfo: {},
  // HEADER
  MEGAMENU__DATA: [],
  SEARCH__TEXT__DATA: [],
  NOTIFICATION__DATA: [],
  SHOW_NOTIFICATION: false,
  // SEARCH
  SEARCH_STRING_RESULT: [],
  SEARCH_STRING_GLOBALTerm: [],
  SEARCH_STRING_GLOBAL: [],
  // FILTER
  BRAND_FILTER_DATA: [],
  COUNTRY_FILTER_DATA: [],
  CATALOG_BRAND_FILTER_DATA: [],
  CATALOG_COUNTRY_FILTER_DATA: [],
  GENERAL_FILTER_DATA: [],
  // CATALOG
  CATALOG_DATA: [],
  MCATALOG__DATA__LIST: [],
  MCATALOG__DATA__TWO__LIST: [],
  // LOGIN PAGE
  TERMS: "",

  // PLP
  CATALOGPLPDATA: [],
  PRODUCTPLPDATA: [],
  PRODUCTPLPDATA_FILTER: [],

  // CMS
  CMS_CONTENT: [],
  LGCHANGE: false,

  // FINANCE
  FINANCE__CUSTOMERINFO: [],
  FINANCE__INVOICE__DATA: [],

  // ORDER
  ORDER__MAIN__DATA: [],
  ORDER__DETAILS__DATA: [],

  // CHECKOUT
  CHECKOUT__ADDRESSLIST__DATA: [],

  // ADDRESS
  ADDRESS__LIST__DATA: [],
  ADDRESS__SUGGESTION__DATA: [],
  ADDRESS__COUNTRY__LIST__DATA: [],
  ADDRESS__REGION__LIST__DATA: [],
  ADDRESS__PROVINCE__LIST__DATA: [],
  ADDRESS__DISTRICT__LIST__DATA: [],
  ADDRESS__SUBDISTRICT__LIST__DATA: [],
  EDIT__ADDRESS__DATA: [],
  ERP__HOLIDAYS__LIST: [],

  // PDP
  PDP__IMAGES__DATA: [],
  PDP__DATA__CONTENT: [],
  SEASONS__DATA__LIST: [],
  ORDERTAKING__DATES__DATA: [],
  SIMILAR__PRODUCTS__DATA: [],



  cart: [],
  summary: [],
  productcount: null,
  currentorderid: null,
  selected: [],
  vat: [],
  cstatus: false,
  splitorder: false,
  user: null,
  manumodal: "",
  ratingmodal: false,
  orderDropDownList: false,
  remainingOrderData: [],
  historybillMonths: [],
  notification: false,
  minimenu: false,
  miniCart: false,
  megamenu: false,
  megamenu_hide: false,
  wishlist: null,
  favdata: [],
  favtg: false,
  wishlistData: [],
  spinner: "",
  roles: [],
  ordercollections: false,
  review: false,
  gt: true,
  thumbs: false,
  language: 1,
  brands: [],
  selection: [],
  cal: false,
  invdata: "",
  scroll: false,
  searchbar: false,
  branchdelete: false,
  loadertrigger: false,
  countryOpen: false,
  campaignProducts: [],
  filteredProducts: [],
  loading: false,
  bannerImage: '',
  responsiveImage: '',
  campaignDetails: {},
  filteredProductscampaign: [],
  productsByType: [],
  filterTitle: '',
  thaiTitle: '',
  filterDetails: '',
  filterdetailsthai: '',
  brandOpen: false,
  clear: false
};

const reducer = (state, action) => {
  switch (action.type) {
    // HOME PAGE
    case "CATALOG__CONTENTS":
      return {
        ...state,
        CATALOG_CONTENTS: action.payload
      }
    case "PROMOTION__CONTENTS":
      return {
        ...state,
        PROMOTION_CONTENT: action.payload
      }
    case "ARRIVAL__CONTENTS":
      return {
        ...state,
        ARRIVAL_CONTENT: action.payload
      }
    case "TRENDING__CONTENTS":
      return {
        ...state,
        TRENDING_CONTENT: action.payload
      }

    case "MAIN__BANNER__CONTENTS":
      return {
        ...state,
        MAINBANNER_CONTENT: action.payload
      }
    case 'PROMOTION_BANNER_DATA':
      return {
        ...state,
        promotionBannerData: action.payload,
      };
    case "SET_DOWNTIME_INFO":
      return {
        ...state,
        downtimeInfo: action.payload
      }

    case "PROMOTION__POPUP":
      return {
        ...state,
        PROMOTION_POPUP_CONTENT: action.payload
      }

    case "PROMOTION__POPUP__CLOSE":
      return {
        ...state,
        PROMOTION_POPUP_CLOSE_CONTENT: action.payload
      }

    case "BRAND__CONTENTS":
      return {
        ...state,
        BRAND__DATA__CONTENT: action.payload
      }

    // HOME PAGE  
    // CATALOG PAGE
    case "CATALOG__PAGE__DATA":
      return {
        ...state,
        CATALOG_DATA: action.payload
      }
    case "MCATALOG__DATA":
      return {
        ...state,
        MCATALOG__DATA__LIST: action.payload
      }
    case "MCATALOG__DATA__TWO":
      return {
        ...state,
        MCATALOG__DATA__TWO__LIST: action.payload
      }
    // CATALOG PAGE
    // HEADER

    case "MEGAMENU__CONTENT":
      return {
        ...state,
        MEGAMENU__DATA: action.payload
      }

    case "SEARCH__TEXT":
      return {
        ...state,
        SEARCH__TEXT__DATA: action.payload
      }

    case "NOTIFICATION__CONTENT":
      return {
        ...state,
        NOTIFICATION__DATA: action.payload
      }

    case "SHOW__HIDE__NOTIFICATION":
      return {
        ...state,
        SHOW_NOTIFICATION: !state.SHOW_NOTIFICATION
      }

    // HEADER

    // SEARCH PAGE
    case "SEARCHSTRING__RESULT":
      return {
        ...state,
        SEARCH_STRING_RESULT: action.payload
      }
    // SEARCH PAGE

    //GOBAL SEARCH
    case "SEARCH_STRING_RESULT_GLOBAL":
      return {
        ...state,
        SEARCH_STRING_GLOBAL: action.payload

      }
    case "ADD_SEARCH_TERM":
      return {
        ...state,
        SEARCH_STRING_GLOBALTerm: [...new Set([...state.SEARCH_STRING_GLOBAL, action.payload.term])], // Add new term and ensure uniqueness
      };
    // FILTER
    case "BRAND__DATA":
      return {
        ...state,
        BRAND_FILTER_DATA: action.payload
      }
    case "COUNTRY__DATA":
      return {
        ...state,
        COUNTRY_FILTER_DATA: action.payload
      }
    case "CATALOGBRAND__DATA":
      return {
        ...state,
        CATALOG_BRAND_FILTER_DATA: action.payload
      }
    case "CATALOGCOUNTRY__DATA":
      return {
        ...state,
        CATALOG_COUNTRY_FILTER_DATA: action.payload
      }
    case "GENERAL__FILTER":
      return {
        ...state,
        GENERAL_FILTER_DATA: action.payload
      }
    // FILTER

    //compaignpage
    case 'SET_CATEGORY':
      return {
        ...state,
        campaignProducts: [],
        loading: true,
      };
    case 'CAMPAIGN_PRODUCTS_SUCCESS':
      return {
        ...state,
        loading: false,
        campaignProducts: [...state.campaignProducts, ...action.payload.products],
        bannerImage: action.payload.bannerImage,
        responsiveImage: action.payload.responsiveImage,
        campaignDetails: action.campaignDetails,
        filterTitle: action.payload.filtertitle,
        filterDetails: action.payload.filterdetails,
        thaiTitle: action.payload.thaititle,
        filterdetailsthai: action.payload.filterdetailsthai
      };

    case 'CAMPAIGN_PRODUCTS_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'CAMPAIGN_PRODUCTS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case 'CAMPAIGN_PRODUCTS_FILTER_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'CAMPAIGN_PRODUCTS_FILTER_SUCCESS':
      return {
        ...state,
        loading: false,
        filteredProductscampaign: action.payload,
      };
    case 'CAMPAIGN_PRODUCTS_FILTER_FAILURE':
      return {
        ...state,
        loading: false,
        // Handle filter failure if needed
      };
    case 'FETCH_PRODUCTS_BY_TYPE_SUCCESS':
      return {
        ...state,
        loading: false,
        productsByType: action.payload, // Store the products fetched by typeId
      };


    // PLP
    case "CATALOGPLP__DATA":
      return {
        ...state,
        CATALOGPLPDATA: action.payload
      }
    case "PRODUCTPLP__DATA":
      return {
        ...state,
        PRODUCTPLPDATA: action.payload
      }
    case "PRODUCTPLP__LEFTFILTER":
      return {
        ...state,
        PRODUCTPLPDATA_FILTER: action.payload
      }
    // PLP

    // CMS
    case "CMS__DATA":
      return {
        ...state,
        CMS_CONTENT: action.payload
      }
    case "LG__CHANGE":
      return {
        ...state,
        LGCHANGE: !state.LGCHANGE
      }
    // CMS

    // FINANCE
    case "FN_CUSTOMERINFO":
      return {
        ...state,
        FINANCE__CUSTOMERINFO: action.payload
      }

    // ORDER
    case "ORDER__MAIN":
      return {
        ...state,
        ORDER__MAIN__DATA: action.payload
      }

    case "ORDER__DETAILS":
      return {
        ...state,
        ORDER__DETAILS__DATA: action.payload
      }

    case "INVOICE__DATA":
      return {
        ...state,
        FINANCE__INVOICE__DATA: action.payload
      }

    // CHECKOUT
    case "CHECKOUT__ADDRESSLIST":
      return {
        ...state,
        CHECKOUT__ADDRESSLIST__DATA: action.payload
      }

    // ADDRESS
    case "GET__ADDRESS__LIST":
      return {
        ...state,
        ADDRESS__LIST__DATA: action.payload
      }

    case "GET__ADDRESS__SUGGESTION":
      return {
        ...state,
        ADDRESS__SUGGESTION__DATA: action.payload
      }

    case "ADDRESS__COUNTRY__DATA":
      return {
        ...state,
        ADDRESS__COUNTRY__LIST__DATA: action.payload
      }

    case "ADDRESS__REGION__DATA":
      return {
        ...state,
        ADDRESS__REGION__LIST__DATA: action.payload
      }

    case "ADDRESS__PROVINCE__DATA":
      return {
        ...state,
        ADDRESS__PROVINCE__LIST__DATA: action.payload
      }

    case "ADDRESS__DISTRICT__DATA":
      return {
        ...state,
        ADDRESS__DISTRICT__LIST__DATA: action.payload
      }

    case "ADDRESS__SUBDISTRICT__DATA":
      return {
        ...state,
        ADDRESS__SUBDISTRICT__LIST__DATA: action.payload
      }

    case "EDIT__ADDRESS":
      return {
        ...state,
        EDIT__ADDRESS__DATA: action.payload
      }

    case "ERP__HOLIDAYS":
      return {
        ...state,
        ERP__HOLIDAYS__LIST: action.payload
      }

    // PDP
    case "PDP__DATA":
      return {
        ...state,
        PDP__DATA__CONTENT: action.payload
      }
    case "CLEAR__PDP__DATA":
      return {
        ...state,
        PDP__IMAGES__DATA: [],
        PDP__DATA__CONTENT: [],
        SIMILAR__PRODUCTS__DATA: [],
        ORDERTAKING__DATES__DATA: [],
        SEASONS__DATA__LIST: [],
      }
    case "SEASONS__DATA":
      return {
        ...state,
        SEASONS__DATA__LIST: action.payload
      }
    case "ORDERTAKING__DATES":
      return {
        ...state,
        ORDERTAKING__DATES__DATA: action.payload
      }
    case "PDP__IMAGES":
      return {
        ...state,
        PDP__IMAGES__DATA: action.payload
      }

    case "SIMILAR__PRODUCTS":
      return {
        ...state,
        SIMILAR__PRODUCTS__DATA: action.payload
      }

    case "CLEAR__SEARCH":
      return {
        ...state,
        searchbar: !state.searchbar,
        clear: !state.clear
      }
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    case "UPDATE_CART":
      return {
        ...state,
        cart: action.data,
      };

    case "SUMMARY_DATA":
      return {
        ...state,
        summary: action.data,
      };

    case "PRODUCT_COUNT":
      return {
        ...state,
        productcount: action.data,
      };

    case "CURRENT_ORDER":
      return {
        ...state,
        currentorderid: action.data,
      };

    case "SELECTED_DATA":
      return {
        ...state,
        selected: action.data,
      };

    case "VAT_DATA":
      return {
        ...state,
        vat: action.data,
      };

    case "ORDERS":
      return {
        ...state,
        ordercollections: !state.ordercollections,
      };

    case "CART_STATUS":
      return {
        ...state,
        cstatus: !state.cstatus,
      };

    case "SPLIT_ORDER":
      return {
        ...state,
        splitorder: !state.splitorder,
      };

    case "SET_MANU_MODAL":
      return {
        ...state,
        manumodal: action.modal,
      };

    case "SET_RATING_MODAL":
      return {
        ...state,
        ratingmodal: action.modal,
      };

    case "ORDER_DROPDOWN":
      return {
        ...state,
        orderDropDownList: action.value,
      };

    case "REMAINING_ORDER":
      return {
        ...state,
        remainingOrderData: action.remain,
      };

    case "HISTORY_MONTHS":
      return {
        ...state,
        historybillMonths: [...state.historybillMonths, action.item],
      };

    case "SET_MINIMENU":
      return {
        ...state,
        minimenu: action.value,
      };

    case "SET_NOTIFICATION":
      return {
        ...state,
        notification: action.value,
      };

    case "SET_MINICART":
      return {
        ...state,
        miniCart: action.value,
      };

    case "SET_MEGAMENU":
      return {
        ...state,
        megamenu: !state.megamenu,
      };

    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.value,
      };

    case "WISHLIST_DATA":
      return {
        ...state,
        wishlistData: action.data,
      };

    case "TRIGGER_WISHLIST":
      return {
        ...state,
        wishlist: !state.wishlist,
      };

    case "USER_ROLES_ID":
      return {
        // ...state,
        roles: action.data,
      };

    case "REVIEW__SELECED":
      return {
        ...state,
        review: action.value,
      };

    case "GENERAL__TRIGGER":
      return {
        ...state,
        gt: !state.gt,
      };

    case "THUMBS__TRIGGER":
      return {
        ...state,
        thumbs: !state.thumbs,
      };
    case "SET_MEGAMENU_HIDE":
      return {
        ...state,
        megamenu_hide: action.value,
      };
    case "BRANDS_DATA":
      return {
        ...state,
        brands: action.brands,
      };
    case "SET_ORDERSTATUS":
      return {
        ...state,
        selection: action.value,
      };
    case "CAL__CLOSE":
      return {
        ...state,
        cal: action.cal,
      };
    case "INV__BILL":
      return {
        ...state,
        invdata: action.data,
      };

    case "SCROLL__TOP":
      return {
        ...state,
        scroll: !state.scroll,
      };

    case "SEARCHBAR__CLOSEOPEN":
      return {
        ...state,
        searchbar: action.status,
      };

    case "BRANCH__DELETED":
      return {
        ...state,
        branchdelete: action.status
      }

    case "LOADER__TRIGGER1":
      return {
        ...state,
        loadertrigger: action.status
      }

    case "BRAND__OPEN":
      return {
        ...state,
        brandOpen: action.value
      }

    case "COUNTRY__OPEN":
      return {
        ...state,
        countryOpen: action.value
      }

    default:
      return state;
  }
};

export default reducer;
