import React, { useEffect, useState, lazy, Suspense } from 'react'
import './PDPPage.scss'
import { useParams } from "react-router-dom";
import BreadCrumbs from '../../components/PDPPage/BreadCrumbs/BreadCrumbs';
import ProductInfo from '../../components/PDPPage/ProductInfo/ProductInfo';
import axios from 'axios';
import request from '../../request';
import { useTranslation } from "react-i18next";
import { useStateValue } from '../../store/state';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PDPPAGE } from '../../store/PDP/Action';

function PDPPage() {
  const { cid, pid, sku } = useParams();
  const { t } = useTranslation();
  const [refreshpdp, setrefreshpdp] = useState(false);
  const { page } = useParams()
  const [{ PDP__DATA__CONTENT }, dispatch] = useStateValue()
  const history = useHistory()
  const [loading, setLoading] = useState(true); // Added loading state

  // PDP API Call
  useEffect(() => {
    setLoading(true); // Start loading

    dispatch({
      type: "CLEAR__PDP__DATA",
    })
    dispatch({
      type: "CLEAR__SEARCH",
    })
    setrefreshpdp(false)
    PDPPAGE(dispatch, page, pid)
    setLoading(false); // End loading
  }, [sku, refreshpdp, localStorage.getItem("storeid")]);


  return (
    <div className='pdppage'>

      {/* BREADCRUMBS */}
      {window.innerWidth > 912 ? <BreadCrumbs breadcrumbs={PDP__DATA__CONTENT?.breadcrumbs} sku={sku} quote={PDP__DATA__CONTENT?.quote} />
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" onClick={() => history.push('/')}>
          <g clip-path="url(#clip0_2119_30916)">
            <path d="M17.5098 3.86998L15.7298 2.09998L5.83984 12L15.7398 21.9L17.5098 20.13L9.37984 12L17.5098 3.86998Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_2119_30916">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      }

      {/* MAIN CONTENT */}
      {loading ? (
        null
      ) : (
        <ProductInfo cid={cid} pid={pid} sku={sku} currentpdpdata={PDP__DATA__CONTENT} setrefreshpdp={setrefreshpdp} />
      )}
    </div>
  )
}

export default PDPPage