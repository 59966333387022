import React, { useEffect, useState } from 'react'
import './Notifications.scss'
import moment from 'moment'
import { READNOTIFICATION } from '../../store/Header/Action'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function Notifications({ NOTIFICATION__DATA, dispatch }) {
  const [currentData, setCurrentData] = useState([])
  const [show, setShow] = useState('All')
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    let data = NOTIFICATION__DATA?.filter(el => 
      el?.category !== "add_to_cart" && !el?.action_data.toLowerCase().includes('added')
    ).slice(0, 100);
  
    setCurrentData(data);
  }, [NOTIFICATION__DATA, show])

  const handleNotificationClick = (el) => {
    if (el.category === "order") {
        const parentOrderId = el.order_id;
        setShow(false);
        history.push(`/myorder/${parentOrderId}`);
        return;
    } else {
        READNOTIFICATION(dispatch, el);
    }
  };

  return (
    <div className='NOTIFICATIONS'>
      {/* <div className='NOTIFICATION__TABS'>
        <p className={`${show === "All" && "NOTIFICATIONS__TAB__SELECTED"}`} onClick={() => setShow("All")}>{t("All")}</p>
      </div> */}
      <div className='NOTIFICATION__CONTENTS'>
        {currentData?.map((el, i) => (
          <span key={i} onClick={() => handleNotificationClick(el)}>
            {el?.category !== "add_to_cart" && (
              <span className='NOTIFICATION__CATEGORY'>
                {el?.category === "order" ? <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none"
                    className='NOTIFICATION__CATEGORY__ORDER'
                  >
                    <g clip-path="url(#clip0_1_15317)">
                      <path d="M14.25 4.5H12.75C12.75 2.43 11.07 0.75 9 0.75C6.93 0.75 5.25 2.43 5.25 4.5H3.75C2.925 4.5 2.25 5.175 2.25 6V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V6C15.75 5.175 15.075 4.5 14.25 4.5ZM9 2.25C10.245 2.25 11.25 3.255 11.25 4.5H6.75C6.75 3.255 7.755 2.25 9 2.25ZM14.25 15H3.75V6H14.25V15ZM9 9C7.755 9 6.75 7.995 6.75 6.75H5.25C5.25 8.82 6.93 10.5 9 10.5C11.07 10.5 12.75 8.82 12.75 6.75H11.25C11.25 7.995 10.245 9 9 9Z" fill="#FC6626" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_15317">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </> : null}
                {el?.category === "notification" ? <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none"
                    className='NOTIFICATION__CATEGORY__NOTIFICATION'
                  >
                    <g clip-path="url(#clip0_1_15376)">
                      <path d="M8.99996 16.5C9.82496 16.5 10.5 15.825 10.5 15H7.49996C7.49996 15.825 8.17496 16.5 8.99996 16.5ZM13.5 12V8.25C13.5 5.9475 12.2775 4.02 10.125 3.51V3C10.125 2.3775 9.62246 1.875 8.99996 1.875C8.37746 1.875 7.87496 2.3775 7.87496 3V3.51C5.72996 4.02 4.49996 5.94 4.49996 8.25V12L2.99996 13.5V14.25H15V13.5L13.5 12ZM12 12.75H5.99996V8.25C5.99996 6.39 7.13246 4.875 8.99996 4.875C10.8675 4.875 12 6.39 12 8.25V12.75ZM5.68496 3.06L4.61246 1.9875C2.81246 3.36 1.62746 5.475 1.52246 7.875H3.02246C3.13496 5.8875 4.15496 4.1475 5.68496 3.06ZM14.9775 7.875H16.4775C16.365 5.475 15.18 3.36 13.3875 1.9875L12.3225 3.06C13.8375 4.1475 14.865 5.8875 14.9775 7.875Z" fill="#2D3EDC" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_15376">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </> : null}
                {el?.category === "account" ? <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none"
                    className='NOTIFICATION__CATEGORY__ACCOUNT'
                  >
                    <path d="M9 2.8125C7.4467 2.8125 6.1875 4.0717 6.1875 5.625C6.1875 7.1783 7.4467 8.4375 9 8.4375C10.5533 8.4375 11.8125 7.1783 11.8125 5.625C11.8125 4.0717 10.5533 2.8125 9 2.8125Z" fill="#37BFA7" />
                    <path d="M6 9.9375C4.4467 9.9375 3.1875 11.1967 3.1875 12.75V13.6412C3.1875 14.2061 3.59691 14.6878 4.15445 14.7788C7.36357 15.3028 10.6364 15.3028 13.8455 14.7788C14.4031 14.6878 14.8125 14.2061 14.8125 13.6412V12.75C14.8125 11.1967 13.5533 9.9375 12 9.9375H11.7443C11.606 9.9375 11.4685 9.95938 11.3369 10.0023L10.6878 10.2143C9.5911 10.5724 8.4089 10.5724 7.31219 10.2143L6.66305 10.0023C6.53152 9.95938 6.39402 9.9375 6.25565 9.9375H6Z" fill="#37BFA7" />
                  </svg>
                </> : null}
                {el?.category === "favourite" ? <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none"
                    className='NOTIFICATION__CATEGORY__FAV'
                  >
                    <g clip-path="url(#clip0_1_15399)">
                      <path d="M9 16.0125L7.9125 15.0225C4.05 11.52 1.5 9.21 1.5 6.375C1.5 4.065 3.315 2.25 5.625 2.25C6.93 2.25 8.1825 2.8575 9 3.8175C9.8175 2.8575 11.07 2.25 12.375 2.25C14.685 2.25 16.5 4.065 16.5 6.375C16.5 9.21 13.95 11.52 10.0875 15.03L9 16.0125Z" fill="#FF1C1C" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_15399">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </> : null}
                <small>{t(el?.category)}</small>
                {el?.category === "notification" || el?.category === "account" ?
                  <strong>{moment(el?.created_at).format("DD MMM")}</strong>
                  :
                  <strong>{moment(el?.created_at).format("hh:ss")}</strong>
                }
              </span>
            )}
             <span className='NOTIFICATION__MESSAGE'>
             <small>
            {el?.category === "order" 
              ? `${el?.action_data} (Order ID: ${el?.order_id})` 
                        : el?.action_data}
                    </small>  
              {(el?.category === "notification" || el?.category === "account") && <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
                className='NOTIFICATION__DOT'
              >
                <circle cx="6" cy="6" r="6" fill="#37BFA7" />
              </svg>}
            </span>
            {el?.category !== null && window.innerWidth > 912 && (el?.category === "order" || el?.category === "favourite") && <small className='NOTIFICATION__CREATED'>{moment(el?.created_at).format("D.M.YYYY hh:ss")}</small>}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Notifications