import React, { useEffect, useState, useRef } from "react";
import "./SearchSuggest.scss";
import { useTranslation } from "react-i18next";
import {
  SEARCHTEXT,
  SEARCHRESULTSGLOBAL,
  SETSEARCHTERMS,
} from "../../store/Header/Action";
import { useStateValue } from "../../store/state";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import request from "../../request";
import Blocks from "../../components/Blocks/Blocks";
import Slider from "react-slick";
import SearchBanner1 from "../../../src/assets/images/search/Searchbanner1.jpg";
import SearchBanner2 from "../../../src/assets/images/search/Searchbanner2.jpg";
import { CATALOGCONTENTS, TRENDINGCONTENT } from "../../store/Home/Actions";

function SearchSuggest({ onProductSelect }) {
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [
    {
      SEARCH__TEXT__DATA,
      SEARCH_STRING_GLOBAL,
      CATALOG_CONTENTS,
      TRENDING_CONTENT,
      SEARCH_STRING_GLOBALTerm,
    },
    dispatch,
  ] = useStateValue();
  const [searchproduct, setsearchproduct] = useState("");
  const [extended, setExtended] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSearchSuggest, setShowSearchSuggest] = useState(false);
  const [searchExtended, setSearchExtended] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);
  const [showSearchButton, setShowSearchButton] = useState(true);
  const searchSuggestRef = useRef(null);
  const searchRef = useRef(null);

  const customer_id = localStorage.getItem("userid");

  const [trendingContentLoaded, setTrendingContentLoaded] = useState(false); // Track if trending content is loaded

  useEffect(() => {
    if (location.state && location.state.search) {
      setsearchproduct(location.state.search);
      setSearchKey(location.state.search);
      setShowSearchSuggest(true)
    }
  }, [location.state]);

  const CustomPrevArrow = ({ onClick }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className="slick-prev"
    >
      <g clipPath="url(#clip0_2783_3813)">
        <path
          d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
          fill="#1C3454"
          fillOpacity="0.26"
        />
      </g>
      <defs>
        <clipPath id="clip0_2783_3813">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const CustomNextArrow = ({ onClick }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className="slick-next"
    >
      <g clipPath="url(#clip0_2783_10433)">
        <path
          d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z"
          fill="#192434"
        />
      </g>
      <defs>
        <clipPath id="clip0_2783_10433">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const sliderSettings = {
    infinite: true,
    slidesToShow: window.innerWidth > 768 ? 5 : 2,
    slidesToScroll: 1,
    speed: 500,
    rows: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  useEffect(() => {
    if (searchproduct === "") {
      setShowDropdown(false);
      return;
    }
    SEARCHTEXT(
      dispatch,
      localStorage.getItem("storeid"),
      localStorage.getItem("userid"),
      searchproduct,
      location,
      history
    );
    setShowDropdown(true);
  }, [searchproduct]);

  const OnSearchEnter = (e) => {
    if (e.key === "Enter") {
      SearchPage();
    }
  };

  useEffect(() => {
    const customer_id = localStorage.getItem("userid");
    SEARCHRESULTSGLOBAL(dispatch, customer_id);
  }, []);

  const SearchPage = () => {
    if (searchproduct === "") return;
    dispatch({
      type: "SEARCH__TEXT",
      payload: [],
    });
    closeSearchSuggest();
    history.push(`/searchpage/${searchproduct}/0`);
  };

  const handleDropdownClick = (product) => {
    SETSEARCHTERMS(dispatch, customer_id, searchKey);
    setsearchproduct(product.name);
    SearchPage();
    closeSearchSuggest();
  };
  const handleClickOutside = (event) => {
    if (
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target) &&
      searchSuggestRef.current &&
      !searchSuggestRef.current.contains(event.target)
    ) {
      setShowSearchSuggest(false);
    }
  };
  const handleRecentSearchClick = (item) => {
    const sanitizedTerm = item.term; // Keep the term as is, including '%'
    const encodedTerm = sanitizedTerm.replace(/%/g, '%20'); // Encode '%' as '%25'
    history.push(`/searchpage/${encodeURIComponent(encodedTerm)}/0`); // Ensure proper encoding
};
  const toggleSearchSuggest = () => {
    setShowSearchSuggest((prev) => !prev);
  };

  const closeSearchSuggest = () => {
    setShowSearchSuggest(false);
    setShowDropdown(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await SETSEARCHTERMS(dispatch, customer_id, searchKey);
      SearchPage();
    }
  };

  const handleInputClick = async () => {
    const customer_id = localStorage.getItem("userid");
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("userid");
      const storeId = localStorage.getItem("storeid");
      await TRENDINGCONTENT(dispatch, userId, storeId);
      setTrendingContentLoaded(true); // Set to true after fetching
      await CATALOGCONTENTS(dispatch, userId, storeId); // Fetch catalog contents
    };

    fetchData();
  }, [dispatch]);

  const itemsToShow = window.innerWidth > 768 ? 3 : 2; // Calculate items to show based on window width

  return (
    <div
      className="SEARCHSUGGEST"
      ref={searchRef}
      onClick={() => onProductSelect("some-suggested-product")}
    >
      <div className="SEARCHSUGGEST__HEADER">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={() =>
            history.push({
              pathname: "/",
            })
          }
        >
          <g clipPath="url(#clip0_1004_29018)">
            <path
              d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
              fill="#37BFA7"
            />
          </g>
          <defs>
            <clipPath id="clip0_1004_29018">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <label>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="search"
      id="search_input"
      placeholder={`${t("Search for item, Category")}`}
      value={searchKey}
      onChange={(e) => {
        setsearchproduct(e.target.value);
        setSearchKey(e.target.value);
        setShowSearchSuggest(true);
      }}
      onClick={handleInputClick}
      onKeyPress={handleKeyPress}
      onFocus={() => setShowSearchSuggest(true)}
      ref={searchInputRef}
      style={{ marginRight: '8px' }}
    />
    
    {showSearchButton && (
          <span className="searchbtn" onClick={SearchPage}>Search</span>
        )}
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={SearchPage} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7882_54359)">
        <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#1C304A" fillOpacity="0.52"/>
      </g>
      <defs>
        <clipPath id="clip0_7882_54359">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</label>

      </div>
      {showSearchSuggest && (
        <div className="search-suggestions">
          {showDropdown && searchproduct && (
            <div className="SEARCHSUGGEST__RESULT">
              {!SEARCH__TEXT__DATA[0]?.products[0]?.html && (
                <ul>
                  {SEARCH__TEXT__DATA[0]?.products?.map((s, i) => (
                    <li key={i} onClick={() => handleDropdownClick(s)}>
                      <img
                        alt=""
                        src={
                          s?.image
                            ? `${request.image}media/catalog/product${s.image}`
                            : ""
                        }
                      />
                      <p>{s.name}</p>
                    </li>
                  ))}
                  <li
                    className="sr__viewall"
                    onClick={async () => {
                      const customer_id = localStorage.getItem("userid");
                      await SETSEARCHTERMS(
                        dispatch,
                        customer_id,
                        searchproduct
                      );
                      setShowDropdown(false);
                      SearchPage();
                    }}
                  >
                    View all ({SEARCH__TEXT__DATA[0]?.total})
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      )}
      <div className="recently_searched">
        <b>Recent Search</b>
        <div
          className="recent_item_card_wrp"
          style={{
            height: extended ? "auto" : "49px",
            overflow: extended ? "auto" : "hidden",
          }}
        >
        {SEARCH_STRING_GLOBAL.length > 0 ? (
            SEARCH_STRING_GLOBAL.map((item, i) => (
              <button
                key={i + 1}
                className="recent_item_button"
                onClick={() => handleRecentSearchClick(item)}
              >
                {item.term}
              </button>
            ))
          ) : (
            <p>No Recent search</p>
)}
        </div>
        <div className="recent_item_extend">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setExtended(!extended)}
            style={{ rotate: extended ? "180deg" : "unset" }}
          >
            <g clipPath="url(#clip0_7882_54398)">
              <path
                d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
                fill="#1B2B41"
                fillOpacity="0.69"
              />
            </g>
            <defs>
              <clipPath id="clip0_7882_54398">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div
        className="RECOMMENDED__SECTION"
        style={{
          display: "flex",
          flexDirection: window.innerWidth > 768 ? "row" : "column",
        }}
      >
        <div style={{ width: window.innerWidth > 768 ? "60%" : "100%" }}>
          <div className="HOME__BLOCKS">
            {CATALOG_CONTENTS?.slice(0, itemsToShow).map(
              (CC, i) => (
                <Blocks
                  key={i}
                  title={t(CC?.details?.title)}
                  id={CC?.details?.category}
                  like={CC?.products}
                  page="searchSuggest"
                  seemore={true}
                  setRefreshAPI={setRefreshAPI}
                  refreshAPI={refreshAPI}
                />
              )
            )}
          </div>
          <div className="TRENDING__SECTION">
            {trendingContentLoaded && TRENDING_CONTENT.length > 0 && TRENDING_CONTENT.slice(0, itemsToShow).map( // Ensure items are displayed immediately
              (item, index) => (
                <Blocks
                  key={index}
                  title={item?.details?.title}
                  id={item?.id}
                  like={item?.products}
                  CATAGORYID={item?.details?.category}
                  CATAGORYNAME={item?.CATAGORYNAME}
                  setRefreshAPI={setRefreshAPI}
                  refreshAPI={refreshAPI}
                  seemore={true}
                  page="searchSuggest"
                  COUNT={item?.products?.length}
                />
              )
            )}
          </div>
        </div>
        <div style={{ width: window.innerWidth > 768 ? "40%" : "100%" }}>
          <div className="IMAGE__SECTION">
            <h1 className="deals__title">{t("Deals")}</h1>
            <div
              className="columns"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="column1"
                style={{ flex: "1", marginRight: "10px" }}
              >
                <Link
                  to={`/campaign/household/${14}/${
                    request.householdCategoryId.STOREID
                  }/${request.householdCategoryId.categoryId}`}
                >
                  <img
                    className="searchbanner1"
                    src={SearchBanner1}
                    alt="Search Banner 1"
                  />
                </Link>
              </div>
              <div className="column2" style={{ flex: "1" }}>
                <Link
                  to={`/campaign/household/${17}/${
                    request.householdCategoryId.STOREID
                  }/${request.householdCategoryId.categoryId}`}
                >
                  <img
                    className="searchnanner2"
                    src={SearchBanner2}
                    alt="Search Banner 2"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchSuggest;
